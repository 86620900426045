import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { FetchRecentActivities } from "../../services/user/dashboard";

// Action creator for fetching TradeActivities
export const fetchTradeActivities = createAsyncThunk(
  "tradeActivities/fetchTradeActivities",
  async () => {
    const response = await FetchRecentActivities();
    return response;
  }
);

// Define initial state
const initialState = {
  data: [],
  loading: false,
  message: "",
  error: null,
};

// Create tradeActivities slice
const tradeActivitiesSlice = createSlice({
  name: "tradeActivities",
  initialState,
  reducers: {
    setTradeActivities(state, action) {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Reducer for fetchTradeActivities
    builder
      .addCase(fetchTradeActivities.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTradeActivities.fulfilled, (state, action) => {
        const response = action.payload;
        state.data = (response && response.data) || [];
        state.message = (response && response.message) || "";
        state.loading = false;
      })
      .addCase(fetchTradeActivities.rejected, (state, action) => {
        state.loading = false;
        state.message = action?.error?.message || "";
        state.error = action?.error?.message || null;
      });
  },
});

export const { setTradeActivities } = tradeActivitiesSlice.actions;

export default tradeActivitiesSlice.reducer;
