import {
  CheckEventSlot,
  ScheduleMeetingForDeposit,
} from "../../../services/user/calendlyMeet";
import { error } from "../../shared/Alert";
import { Constants } from "./constants";

export const handleScheduleMeeting = (token, values, setLoading, setStatus) => {
  if (!token) return;

  if (token) {
    setLoading(true);
    ScheduleMeetingForDeposit({
      eventUrl: values.event.uri,
      inviteeUrl: values.invitee.uri,
      token,
    }).then((res) => {
      setLoading(false);
      setStatus(Constants._BOOKED);
    });
  } else {
    error(Constants.NOT_AUTHORIZED);
  }
};

export const handleCheckEventSlot = (token, setLoading, setStatus) => {
  if (!token) return;

  CheckEventSlot({ token }).then((res) => {
    if (res.status === 200) {
      setStatus(Constants._VALID);
    } else {
      setStatus(res.message);
    }
    setTimeout(() => {
      setLoading(false);
    }, 500);
  });
};
