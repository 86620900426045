import React from "react";
import Form from "./Form";
import Header from "../../../../shared/Header";
import { TWO_FA_PROMPT } from "./constants";
import { SECURITY_MESSAGE } from "../../../../auth/VerifyFA/constants";

const Verify2FAEmailChange = ({ setEmail, token, email }) => {
  return (
    <Header heading={TWO_FA_PROMPT} subHeading={SECURITY_MESSAGE}>
      <Form setEmail={setEmail} token={token} email={email} />
    </Header>
  );
};

export default Verify2FAEmailChange;
