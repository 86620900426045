import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllUsers } from "../../services/admin/users";

// Action creator for fetching fetchAllUsers
export const fetchAllUsers = createAsyncThunk(
  "users/fetchAllUsers",
  async () => {
    const response = await getAllUsers();
    return response;
  }
);

// Define initial state
const initialState = {
  allUsers: [],
  loading: false,
  message: "",
  error: null,
};

// Create slice for users
const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setAllUsers(state, action) {
      state.allUsers = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Reducer for fetchAllUsers
    builder
      .addCase(fetchAllUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllUsers.fulfilled, (state, action) => {
        const response = action.payload;
        state.allUsers = (response && response.data) || [];
        state.message = (response && response.message) || "";
        state.loading = false;
      })
      .addCase(fetchAllUsers.rejected, (state, action) => {
        state.loading = false;
        state.message = action?.error?.message || "";
        state.error = action?.error?.message || null;
      });
  },
});

export const { setAllUsers } = usersSlice.actions;

export default usersSlice.reducer;
