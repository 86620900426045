import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GetBankAccountDetails } from "../../services/user/bankAccount";

// Action creator for fetching userBankDetail
export const fetchUserBankDetail = createAsyncThunk(
  "userBankDetail/fetchUserBankDetail",
  async () => {
    const response = await GetBankAccountDetails();
    return response;
  }
);

// Define initial state
const initialState = {
  data: null,
  loading: false,
  message: "",
  error: null,
};

// Create tradeActivities slice
const userBankDetailSlice = createSlice({
  name: "userBankDetail",
  initialState,
  reducers: {
    setUserBankDetail(state, action) {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Reducer for fetchUserBankDetail
    builder
      .addCase(fetchUserBankDetail.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUserBankDetail.fulfilled, (state, action) => {
        const response = action.payload;
        state.data = (response && response.data) || [];
        state.message = (response && response.message) || "";
        state.loading = false;
      })
      .addCase(fetchUserBankDetail.rejected, (state, action) => {
        state.loading = false;
        state.message = action?.error?.message || "";
        state.error = action?.error?.message || null;
      });
  },
});

export const { setUserBankDetail } = userBankDetailSlice.actions;

export default userBankDetailSlice.reducer;
