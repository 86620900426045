import axios from "axios";
import { serverUrl } from "../../config";
import { ParseError } from "../../config/utils";
import { useDeviceData } from "react-device-detect";
import instance from "../../config/axios";
import { ClearSession } from "../../config/axios";
import { isObject } from "lodash";

export const GetIP = async () => {
  const res = await axios.get("https://api.ipify.org/?format=json");
  return (res && res.data && res.data.ip) || "";
};

export const getLoginDetails = async () => {
  try {
    const ip = await GetIP();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { browser, device, os } = useDeviceData();
    const data = {
      ip: ip,
      browser: browser,
      device: device,
      os: os,
    };
    return data;
  } catch (err) {
    console.log("err>", err);
    return null;
  }
};

export const UserLogin = async (formData) => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };

  return instance
    .post(serverUrl + "/auth", formData, {
      withCredentials: true,
    })
    .then(async (response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          ...responseData,
          ...response,
          status: 200,
          message: response.message || "",
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      console.log(err.response);
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const UserRegister = async (formData) => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  return instance
    .post(serverUrl + "/auth/register", formData)
    .then((response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          ...responseData,
          status: 200,
          data: response.userInfo,
          message: response.message,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      console.log(err, "err in catch of user register");
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const UserEmailStatus = async (email) => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  return instance
    .post(serverUrl + "/auth/status/email", { email: email })
    .then((response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          ...responseData,
          status: 200,
          message: response.message,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      console.log(err, "err in catch of user emmaill status");
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const VerifyEmailToken = async (token) => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  return axios
    .get(serverUrl + "/auth/verify-email-token/" + token)
    .then((response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          ...responseData,
          status: 200,
          message: response.message,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      console.log(err.response);
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const AccountConfirmation = async (formData) => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  return axios
    .post(serverUrl + "/auth/confirmation", formData)
    .then((response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          ...responseData,
          ...response,
          status: 200,
          message: response.message,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      console.log(err.response);
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const UserResetPassword = async (formData) => {
  return {
    code: 200,
    message: "User signed up successfully",
  };
};

export const UserSetPassword = async (formData) => {
  return {
    code: 200,
    message: "User signed up successfully",
  };
};

export const UserVerify2FA = async (formData) => {
  return {
    code: 200,
    message: "User signed up successfully",
  };
};

export const GetTwoFA = async (id) => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };

  return instance
    .get(serverUrl + "/auth/generateTwoFa/" + id)
    .then((response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          ...responseData,
          data: response.data,
          google2faSecret: response.google2faSecret,
          status: 200,
          message: response.message,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      console.log(err.response);
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const EnableTwoFa = async (formData) => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };

  return instance
    .post(serverUrl + "/auth/enableTwoFa", formData)
    .then((response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          ...responseData,
          data: isObject(response.userInfo) ? response.userInfo : {},
          status: 200,
          message: response.message,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      console.log(err.response);
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const EnableTwoFaAndLogin = async (formData) => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };

  return instance
    .post(serverUrl + "/auth/enableTwoFaAndLogin", formData, {
      withCredentials: true,
    })
    .then((response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          ...responseData,
          ...response,
          status: 200,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      console.log(err.response);
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const VerifyTwoFaAndLogin = async (formData) => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };

  const ip = await GetIP();
  const { browser, device, os } = useDeviceData();
  const data = {
    ...formData,
    ip: ip,
    browser: browser,
    device: device,
    os: os,
  };
  return instance
    .post(serverUrl + "/auth/verifyTwoFaAndLogin", data, {
      withCredentials: true,
    })
    .then((response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;

        return {
          ...responseData,
          ...response,
          status: 200,
          message: response.message,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      console.log(err.response);
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const ForgotPassword = async (formData) => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  return axios
    .post(serverUrl + "/auth/forgotPassword", formData)
    .then((response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          ...responseData,
          status: 200,
          message: response.message,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      console.log(err.response);
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const ResetPassword = async (formData) => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  return axios
    .post(serverUrl + "/auth/resetPassword", formData)
    .then((response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          ...responseData,
          status: 200,
          message: response.message,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      console.log(err.response);
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const Logout = async () => {
  await ClearSession();
  window.location = "/login";
};

export const UserResendEmail = async (formData) => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  return instance
    .post(serverUrl + "/auth/resend-verification-email", formData)
    .then((response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          ...responseData,
          status: 200,
          message: response.message,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      console.log(err, "err in catch of user register");
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};
