import React from "react";

const Checkbox = ({
  name = "",
  disabled = false,
  checked = false,
  onChange = () => {},
  className = "",
}) => {
  return (
    <input
      disabled={disabled}
      className={`form-check-input radio-btn cursor-pointer ${className}`}
      type="checkbox"
      name={name}
      checked={checked}
      onChange={onChange}
    />
  );
};

export default Checkbox;
