import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { defaultEmailValue } from "../helper";
import Verify2FAEmailChange from "./Verify2FA";
import { PENDING } from "../constants";
import { ClearSession } from "../../../../config/axios";

const ChangeEmailVerify = () => {
  let { token } = useParams();
  const [email, setEmail] = useState(defaultEmailValue);
  useEffect(() => {
    ClearSession();
  }, []);

  return (
    <>
      {email.status === PENDING && (
        <Verify2FAEmailChange setEmail={setEmail} token={token} email={email} />
      )}
    </>
  );
};

export default ChangeEmailVerify;
