import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getCoins, getAllCoins } from "../../services/user/coins";

// Action creator for fetching coins
export const fetchCoins = createAsyncThunk("coins/fetchCoins", async () => {
  const response = await getCoins();
  return response;
});

// Action creator for fetching coins
export const fetchAllCoins = createAsyncThunk(
  "coins/fetchAllCoins",
  async () => {
    const response = await getAllCoins();
    return response;
  }
);

// Define initial state
const initialState = {
  coins: [],
  allCoins: [],
  loading: false,
  coinLoading: false,
  message: "",
  coinMessage: "",
  error: null,
  coinError: null,
};

// Create coins slice
const coinsSlice = createSlice({
  name: "coins",
  initialState,
  reducers: {
    setCoins(state, action) {
      state.coins = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Reducer for fetchCoins
    builder
      .addCase(fetchCoins.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCoins.fulfilled, (state, action) => {
        const response = action.payload;
        state.coins = (response && response.data) || [];
        state.message = (response && response.message) || "";
        state.loading = false;
      })
      .addCase(fetchCoins.rejected, (state, action) => {
        state.loading = false;
        state.message = action?.error?.message || "";
        state.error = action?.error?.message || null;
      });

    // Reducer for fetchAllCoins
    builder
      .addCase(fetchAllCoins.pending, (state) => {
        state.coinLoading = true;
      })
      .addCase(fetchAllCoins.fulfilled, (state, action) => {
        const response = action.payload;
        state.allCoins = (response && response.data) || [];
        state.coinMessage = (response && response.message) || "";
        state.coinLoading = false;
      })
      .addCase(fetchAllCoins.rejected, (state, action) => {
        state.coinLoading = false;
        state.coinMessage = action?.error?.message || "";
        state.coinError = action?.error?.message || null;
      });
  },
});

export const { setCoins } = coinsSlice.actions;

export default coinsSlice.reducer;
