import { serverUrl } from "../../config";
import { ParseError, getBearerToken } from "../../config/utils";
import instance from "../../config/axios";

export const GetUserProfile = async () => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };

  const headers = await getBearerToken();

  return instance
    .get(serverUrl + "/users/profile", headers)
    .then(async (response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;

        return {
          ...responseData,
          data: response.user,
          status: 200,
          message: response.message || "",
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      console.log(err.response);
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};
export const GetSumSubToken = async (id) => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };

  const headers = await getBearerToken();

  return instance
    .get(serverUrl + `/users/profile/sumsubtoken?id=${id}`, headers)
    .then(async (response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;

        return {
          ...responseData,
          data: response.user,
          status: 200,
          message: response.message || "",
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      console.log(err.response);
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};
export const UpdateKYCLevel = async (id, level) => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };

  const headers = await getBearerToken();

  return instance
    .put(serverUrl + `/users/profile/changeKYCLevel`, { id, level }, headers)
    .then(async (response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;

        return {
          ...responseData,
          data: response.user,
          status: 200,
          message: response.message || "",
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      console.log(err.response);
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};
export const UpdateUserProfile = async (formData) => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };

  const headers = await getBearerToken();

  return instance
    .put(serverUrl + "/users/profile", formData, headers)
    .then(async (response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          ...responseData,
          data: response.data,
          status: 200,
          message: response.message || "",
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      console.log(err.response);
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const ChangeUserPassword = async (formData) => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };

  const headers = await getBearerToken();

  return instance
    .put(serverUrl + "/users/profile/changePassword", formData, headers)
    .then(async (response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          status: 200,
          message: response.message || "",
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      console.log(err.response);
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const GetReset2FA = async () => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };

  const headers = await getBearerToken();

  return instance
    .get(serverUrl + "/users/profile/resetTwoFa", headers)
    .then(async (response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          status: 200,
          message: response.message || "",
          data: response.data,
          google2faSecret: response.google2faSecret,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const VerifyReset2FA = async (data) => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };

  const headers = await getBearerToken();

  return instance
    .post(serverUrl + "/users/profile/verifyResetTwoFa", data, headers)
    .then(async (response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          status: 200,
          message: response.message || "",
          data: response.data,
          google2faSecret: response.google2faSecret,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const ChangeUserEmail = async (formData) => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };

  const headers = await getBearerToken();

  return instance
    .put(serverUrl + "/users/profile/changeEmail", formData, headers)
    .then(async (response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          status: 200,
          message: response.message || "",
          data: response.data,
          google2faSecret: response.google2faSecret,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const VerifyChangeEmail = async (token, verificationCode) => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };

  return instance
    .get(
      serverUrl +
        `/users/profile/verifyChangeEmail/${token}?verificationCode=${verificationCode}`
    )
    .then(async (response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          status: 200,
          message: response.message || "",
          data: response.data,
          google2faSecret: response.google2faSecret,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const VerifyCurrentPassword = async (formData) => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };

  const headers = await getBearerToken();

  return instance
    .post(serverUrl + "/users/profile/verifyCurrentPassword", formData, headers)
    .then(async (response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          status: 200,
          message: response.message || "",
          emailToken: response.emailToken,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const GetUserActivities = async (page = 1, offset = 10, search) => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };

  const headers = await getBearerToken();

  return instance
    .get(
      serverUrl +
        `/users/activities?page=${page}&offset=${offset}&searchQuery=${search}`,
      headers
    )
    .then(async (response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;

        return {
          ...responseData,
          data: response.data,
          total: response.total,
          status: 200,
          message: response.message || "",
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      console.log(err.response);
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const GetUserLogs = async (page = 1, offset = 10, search) => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };

  const headers = await getBearerToken();

  return instance
    .get(
      serverUrl +
        `/users/userLogs?page=${page}&offset=${offset}&searchQuery=${search}`,
      headers
    )
    .then(async (response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;

        return {
          ...responseData,
          data: response.data,
          total: response.total,
          status: 200,
          message: response.message || "",
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      console.log(err.response);
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const UpdateUserConsentInformation = async (formData) => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };

  const headers = await getBearerToken();

  return instance
    .put(serverUrl + "/users/profile/consent", formData, headers)
    .then(async (response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          status: 200,
          message: response.message || "",
          data: response.data,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};
