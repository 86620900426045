import axios from "axios";
import { serverUrl } from "../../../config";
import { ParseError } from "../../../config/utils";

export const CheckEventSlot = async (formData) => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  return axios
    .post(serverUrl + "/calendly-meet/check", formData)
    .then((response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          ...responseData,
          status: 200,
          message: response.message,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      console.log(err.response);
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const ScheduleMeetingForDeposit = async (formData) => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  return axios
    .post(serverUrl + "/calendly-meet/schedule", formData)
    .then((response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          ...responseData,
          status: 200,
          message: response.message,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      console.log(err.response);
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};
