import { createSlice } from "@reduxjs/toolkit";
import {
  setLocalStorageItem,
  getLocalStorageItem,
  getLocalStorageItemExect,
  decryptData,
} from "../../config/AuthSetting";

const initialState = {
  token: getLocalStorageItem("token") || null,
  permissions: getLocalStorageItem("permissions") || null,
  user: getLocalStorageItem("user") || null,
  userInfo: getLocalStorageItem("userInfo") || null,
  loginKey: getLocalStorageItemExect("loginKey") || null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
      setLocalStorageItem("token", action.payload);
    },

    removeToken: (state) => {
      localStorage.removeItem("token");
      state.token = null;
    },

    setUser: (state, action) => {
      const user = JSON.stringify(action.payload);
      state.user = user;
      setLocalStorageItem("user", user);
    },

    removeUser: (state) => {
      localStorage.removeItem("user");
      state.user = null;
    },

    setUserInfo: (state, action) => {
      state.userInfo = decryptData(action.payload);
      localStorage.setItem("userInfo", action.payload);
    },

    setPermissions: (state, action) => {
      state.permissions = action.payload;
      localStorage.setItem("permissions", action.payload);
    },

    setLoginKey: (state, action) => {
      state.loginKey = action.payload;
      localStorage.setItem("loginKey", action.payload);
    },

    removeLoginKey: (state) => {
      localStorage.removeItem("loginKey");
      state.userInfo = null;
    },

    removeUserInfo: (state) => {
      localStorage.removeItem("userInfo");
      state.userInfo = null;
    },
    removePermissions: (state) => {
      localStorage.removeItem("permissions");
      state.permissions = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setUserInfo,
  removeUserInfo,
  setPermissions,
  removePermissions,
  setUser,
  removeUser,
  setToken,
  removeToken,
  setLoginKey,
  removeLoginKey,
} = authSlice.actions;

export default authSlice.reducer;
