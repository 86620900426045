import axios from "axios";
import { serverUrl } from "./index";
import { clearLocalStorageItems } from "./AuthSetting";
import { Logout } from "../services/auth";
import { decryptData, encryptData } from "../utils/EncryptDecrypt";

const instance = axios.create({
  baseURL: serverUrl,
});
export const ClearSession = async () => {
  await instance.get(serverUrl + "/auth/logout", {
    withCredentials: true,
  });

  await clearLocalStorageItems();
  return true;
};

// instance.interceptors.request.use(
//   async (config) => {
//     try {
//       return config;
//     } catch (error) {
//       return Promise.reject(error);
//     }
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );
instance.interceptors.response.use(
  async function (response) {
    return response;
  },
  async function (error) {
    if (error?.response?.status === 409) {
      Logout();
    }
    const originalRequest = error.config;
    if (error?.response?.status === 401 && !originalRequest?._retry) {
      originalRequest._retry = true; // Set the _retry flag to true to avoid infinite loop
      try {
        const resp = await instance.get(serverUrl + "/auth/token", {
          withCredentials: true,
        });

        if (resp.status === 200 && resp.data && resp.data.token) {
          const accessToken = resp.data.token;
          let { permissions } = decryptData(accessToken);
          permissions = encryptData(permissions);
          localStorage.setItem("permissions", permissions);
          localStorage.setItem("token", accessToken);

          instance.defaults.headers.common.authorization = `Bearer ${accessToken}`;
          originalRequest.headers.authorization = `Bearer ${accessToken}`;
          // Retry the original request
          return instance(originalRequest);
        } else {
          Logout();
        }
      } catch (refreshError) {
        console.log("refreshError>>", refreshError);
        return Promise.reject(refreshError);
      }
    } else {
      console.log("error>>", error);
      return Promise.reject(error);
    }
  }
);

export default instance;
