import { serverUrl } from "../../../config";
import { ParseError, getBearerToken } from "../../../config/utils";
import instance from "../../../config/axios";

const responseData = {
  status: 210,
  message: "Something went wrong, Please try again.",
};

export const GetBankAccountDetails = async () => {
  const headers = await getBearerToken();

  return instance
    .get(serverUrl + "/users/profile/bankDetail", headers)
    .then(async (response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;

        return {
          ...responseData,
          data: response.user,
          status: 200,
          message: response.message || "",
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        status: err?.response?.data?.code || 201,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const CreateBankAccount = async () => {
  const headers = await getBearerToken();

  return instance
    .post(serverUrl + "/users/profile/bankAccount", headers)
    .then(async (response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;

        return {
          ...responseData,
          data: response.data,
          status: 200,
          message: response.message || "",
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const CreateWithdrawBankAccount = async (data) => {
  const headers = await getBearerToken();

  return instance
    .post(serverUrl + "/users/profile/bankAccount/withdraw", data, headers)
    .then(async (response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;

        return {
          ...responseData,
          data: response.data,
          status: 200,
          message: response.message || "",
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const UpdateWithdrawBankAccount = async (data, id) => {
  const headers = await getBearerToken();

  return instance
    .put(serverUrl + `/users/profile/bankAccount/withdraw/${id}`, data, headers)
    .then(async (response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;

        return {
          ...responseData,
          data: response.data,
          status: 200,
          message: response.message || "",
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};
