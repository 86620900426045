import React, { useCallback } from "react";
import { useForm } from "react-hook-form";
import FormBody from "./FormBody";
import { useDispatch } from "react-redux";
import { defaultValues, handleUpdateConsent } from "./helper";
import { setUserInfo } from "../../../store/auth";

const Form = () => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    watch,
    trigger,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: defaultValues,
  });

  const handleSetUserInfo = useCallback(
    (userInfo) => {
      dispatch(setUserInfo(userInfo));
    },
    [dispatch]
  );

  const onSubmit = async (data) => {
    await handleUpdateConsent(data, handleSetUserInfo);
  };

  return (
    <form
      onSubmit={(e) => {
        return handleSubmit(onSubmit)(e);
      }}
    >
      <FormBody
        register={register}
        errors={errors}
        isSubmitting={isSubmitting}
        watch={watch}
        trigger={trigger}
        setValue={setValue}
      />
    </form>
  );
};

export default Form;
