import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getUserFiatBalance,
  getUserCryptoBalance,
} from "../../services/user/dashboard";

// Action creator for fetching user balances
export const fetchBalances = createAsyncThunk(
  "user/fetchBalances",
  async () => {
    // Fetch crypto balances
    let message = "";
    const fiatBalanceResp = await getUserFiatBalance();
    const fiatBalances = fiatBalanceResp.data || {};
    if (fiatBalanceResp.message) {
      message = message + " " + fiatBalanceResp.message;
    }

    const cryptoBalanceResp = await getUserCryptoBalance();
    const cryptoBalances = cryptoBalanceResp.data || {};
    if (cryptoBalanceResp.message) {
      message = message + " " + cryptoBalanceResp.message;
    }

    // Combine both balances into a single array
    const balances = {
      fiat: fiatBalances?.fiat || "0",
      usdt: cryptoBalances?.usdt || "0",
      btc: cryptoBalances?.btc || "0",
      eth: cryptoBalances?.eth || "0",
    };

    return {
      data: balances,
      message,
    };
  }
);

// Define initial state
const initialState = {
  balances: [],
  loading: false,
  message: "",
  error: null,
};

// Create balances slice
const balancesSlice = createSlice({
  name: "balances",
  initialState,
  reducers: {
    setBalances(state, action) {
      state.balances = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Reducer for fetchBalances
    builder
      .addCase(fetchBalances.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchBalances.fulfilled, (state, action) => {
        const response = action.payload;
        state.balances = (response && response.data) || [];
        state.message = (response && response.message) || "";
        state.loading = false;
      })
      .addCase(fetchBalances.rejected, (state, action) => {
        state.loading = false;
        state.message = action?.error?.message || "";
        state.error = action?.error?.message || null;
      });
  },
});

export const { setBalances } = balancesSlice.actions;

export default balancesSlice.reducer;
