import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth";
import coinsReducer from "./coins";
import usersReducer from "./users";
import balancesReducer from "./balances";
import tradeActivitiesReducer from "./tradeActivities";
import userProfileReducer from "./userProfile";
import userBankDetailReducer from "./userBankDetail";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    coins: coinsReducer,
    users: usersReducer,
    balances: balancesReducer,
    tradeActivities: tradeActivitiesReducer,
    userProfile: userProfileReducer,
    userBankDetail: userBankDetailReducer,
  },
});
