import React from "react";
import { useForm } from "react-hook-form";
import FormBody from "./FormBody";
import {
  initialVerify2FAValues,
  verify2FASchema,
  verifyChangeEmailHandler,
} from "./helper";
import { zodResolver } from "@hookform/resolvers/zod";

const Form = ({ setEmail, token, email }) => {
  const {
    handleSubmit,
    watch,
    trigger,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: initialVerify2FAValues,
    resolver: zodResolver(verify2FASchema),
  });

  const onSubmit = async (data) => {
    await verifyChangeEmailHandler(setEmail, token, data?.verificationCode);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormBody
        watch={watch}
        trigger={trigger}
        setValue={setValue}
        errors={errors}
        isSubmitting={isSubmitting}
        email={email}
      />
    </form>
  );
};

export default Form;
