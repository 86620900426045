import * as z from "zod";
import { ClearSession } from "../../../../../config/axios";
import { VerifyChangeEmail } from "../../../../../services/user";
import { error } from "../../../../shared/Alert";
import { SUCCESS } from "../../constants";

export const verifyChangeEmailHandler = async (
  setEmail,
  token,
  verificationCode
) => {
  const resp = await VerifyChangeEmail(token, verificationCode);

  if (resp.status === 200) {
    setEmail(() => ({ status: SUCCESS }));
    await ClearSession();
    window.location = "/";
  } else {
    error(resp.message);
  }
};

export const verify2FASchema = z.object({
  verificationCode: z
    .string({ required_error: "Verification code is required" })
    .min(6, { message: "Please input the 6 digits 2FA code" })
    .max(6, { message: "Please input the 6 digits 2FA code" }),
});

export const initialVerify2FAValues = {
  verificationCode: "",
};
