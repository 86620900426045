import { serverUrl } from "../../../config";
import { ParseError, getBearerToken } from "../../../config/utils";
import instance from "../../../config/axios";

const responseData = {
  status: 210,
  message: "Something went wrong, Please try again.",
};

const fetchData = async (url) => {
  try {
    const headers = await getBearerToken();
    const response = await instance.get(url, headers);

    if (response.data && response.data.code === 200) {
      return {
        status: 200,
        message: response.data.message || "",
        data: response.data.data,
      };
    } else {
      return {
        ...responseData,
        message: ParseError(response.data),
      };
    }
  } catch (err) {
    return {
      ...responseData,
      message: ParseError(
        err.response && err.response.data ? err.response.data : err.message
      ),
    };
  }
};

export const getUserFiatBalance = async () => {
  return await fetchData(serverUrl + "/users/profile/balance");
};

export const getUserCryptoBalance = async () => {
  return await fetchData(serverUrl + "/users/profile/cryptoBalance");
};

export const FetchRecentActivities = async () => {
  const headers = await getBearerToken();

  return instance
    .get(serverUrl + "/users/trades", headers)
    .then(async (response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;

        return {
          ...responseData,
          data: response.data,
          status: 200,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        status: err?.response?.data?.code || 201,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};
