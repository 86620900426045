import cryptoJS from "crypto-js";
const Securitykey = process.env.REACT_APP_ENCRYPTER;

const encryptData = (data) => {
  try {
    if (!data) return "";
    const encrypted = cryptoJS.AES.encrypt(
      JSON.stringify(data),
      Securitykey
    ).toString();

    return encrypted;
  } catch (err) {
    return "";
  }
};
const decryptData = (data) => {
  try {
    if (!data) return "";
    const decrypted = cryptoJS.AES.decrypt(data, Securitykey).toString(
      cryptoJS.enc.Utf8
    );
    return JSON.parse(decrypted);
  } catch (err) {
    console.log("err in decryptData>>", err);
    return "";
  }
};

export { encryptData, decryptData };
